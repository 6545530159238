<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin' && rol != 'admin-empresa' && rol != 'admin-sucursal'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>      
        <v-container xl fluid v-else>
            <v-row>
                <v-overlay :value="overlayDescarga" >
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1"
                        >
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60"
                            ></v-img>
                        </v-progress-circular>
                        <p>{{frase}}</p>
                    </div>
                </v-overlay>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Beneficiarios'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        :showAdvancedFilters="true"
                        @loadModalData="loadModalData"
                        @setFilters="setFilters"
                        :perPage="[10,25,50,100]"
                    >   
                        <template
                            v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa' || rol == 'admin-sucursal'"
                            slot="actionButtonsLeft"
                        >
                            <v-menu offset-y content-class="menuClassAcciones">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        dark
                                        class="btnAcciones pr-1 mr-3"
                                        v-bind="attrs"
                                        v-on="on"
                                        elevation="0"
                                    >
                                        Acciones 
                                        <template  v-for="(item,valor) in attrs" >
                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#FFFFFF'>expand_less</v-icon>
                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#FFFFFF'>expand_more</v-icon>
                                        </template>
                                    </v-btn>
                                    
                                </template>
                                <div class="btnAccionesContenedor">
                                    <div class="btnAccionesTitulo pb-2">
                                        <span>Layout</span>
                                    </div>

                                    <template v-if="rol == 'root' || rol == 'admin'">
                                        <div @click="abrirModalLayoutEmpleados('descargar')" class="btnAccionesItem py-2">
                                            <span>Descargar layout</span>
                                        </div>
                                        <div @click="abrirModalLayoutEmpleados('subir')" class="btnAccionesItem py-2">
                                            <span>Importar layout</span><br />
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div @click="layout()" class="btnAccionesItem py-2">
                                            <span>Descargar layout</span>
                                        </div>
                                        <div @click="modalLayout('importar')" class="btnAccionesItem py-2">
                                            <span>Importar layout</span><br />
                                        </div>
                                    </template>
                                </div>
                            </v-menu>
                        </template>
                        <template slot="filters">
                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="6" md="6" class="py-0">  
                                <v-autocomplete
                                    outlined
                                    label="Cliente"
                                    :items="itemsClientes"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="cliente_value"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col v-if="rol == 'root' || rol == 'admin'" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="itemsEmpresas"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Empresa"
                                    persistent-hint
                                    v-model="empresa_value"
                                ></v-autocomplete>
                            </v-col> 
                            <v-col v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="itemsSucursales"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Sucursal"
                                    persistent-hint
                                    v-model="sucursal_value"
                                ></v-autocomplete>
                            </v-col>
                            <v-col xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="itemsRegistrosPatronales"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Registro patronal"
                                    persistent-hint
                                    v-model="registro_patronal_value"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    label="Tipo de nómina"
                                    :items="itemsTiposNominas"
                                    item-text="claveDescripcion"
                                    item-value="id"
                                    persistent-hint
                                    v-model="tipo_nomina_value"
                                    class="pa-0 ma-0"
                                    id="tipoNominaID"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-text-field
                                    outlined
                                    label="Nombre empleado"
                                    v-model="nombre_completo_value"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-text-field
                                    outlined
                                    label="Correo"
                                    v-model="correo_value"
                                />
                            </v-col>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">

                                <expandable-item 
                                    :item="item"
                                    :key="item.id"
                                    @abrirModal="abrirModal"
                                    @abrirModalUpdate="abrirModalUpdate"
                                    @eliminar="eliminar"
                                >
                                </expandable-item>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="700px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" class="d-flex justify-center my-10" v-if="isLoadingModal">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-if="!isLoadingModal" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                <v-text-field
                                                    outlined
                                                    label="Nombre"
                                                    v-model="beneficiario.nombre"
                                                    :error-messages="errors"
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Apellido paterno" rules="required">
                                                <v-text-field
                                                    outlined
                                                    label="Apellido paterno"
                                                    v-model="beneficiario.apellido_paterno"
                                                    :error-messages="errors"
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Apellido materno" rules="required">
                                                <v-text-field
                                                    outlined
                                                    label="Apellido materno"
                                                    v-model="beneficiario.apellido_materno"
                                                    :error-messages="errors"
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                            <VuetifyDatePicker
                                                outlined
                                                rules="required"
                                                label="Fecha de nacimiento"
                                                :valorDefecto="beneficiario.fecha_nacimiento"
                                                v-model="beneficiario.fecha_nacimiento"
                                                placeholder="dd/mm/aaaa"
                                                ref="FechaNacimiento"
                                            />
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Parentesco" rules="required">
                                                <v-text-field
                                                    outlined
                                                    label="Parentesco"
                                                    v-model="beneficiario.parentesco"
                                                    required
                                                    :error-messages="errors"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Correo" rules="required|email">
                                                <v-text-field
                                                    outlined
                                                    label="Correo"
                                                    v-model="beneficiario.correo"
                                                    required
                                                    :error-messages="errors"
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Teléfono" rules="required">
                                                <v-text-field
                                                    outlined
                                                    label="Teléfono"
                                                    v-model.number="beneficiario.telefono"
                                                    :error-messages="errors"
                                                    auto
                                                    v-mask="'(###) ###-####'"
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                         <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                            <template>
                                                <v-container class="ma-0 pa-0" fluid>
                                                    <h4 class="tituloRadio">Sexo</h4>
                                                    <v-radio-group class="ma-0 pa-0" v-model="beneficiario.sexo" row>
                                                        <v-radio label="Masculino" value="M"></v-radio>
                                                        <v-radio label="Femenino" value="F"></v-radio>
                                                    </v-radio-group>
                                                </v-container>
                                            </template>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardar()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>



            <template>
                <v-dialog v-model="dialogLayoutEmpleados" persistent max-width="500px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" style="display: none;" class="botonModal" id="btnModalLayoutEmpleados"></v-btn>
                    </template>
                    <v-card>
                        <ValidationObserver ref="formArchivo" v-slot="{ invalid }">
                            <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                                <div class="headerModalStart">
                                    <h2 class="titleModal_700_18">{{ tituloLayoutEmpleados }}</h2>
                                </div>
                            </v-card-title>
                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoading">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Cliente"
                                                    :items="itemsClientes"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="cliente_value"
                                                    required
                                                    :error-messages="errors" 
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Empresa"
                                                    :items="itemsEmpresas"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="empresa_value"
                                                    required 
                                                    :error-messages="errors" 
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="accionLayout === 'subir'" cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Layout" rules="required">
                                                <v-file-input
                                                    outlined
                                                    label="Seleccionar el documento"
                                                    v-model="archivo.archivo"
                                                    accept=".xlsx"
                                                    required 
                                                    :error-messages="errors"
                                                    prepend-icon=""
                                                    append-icon="attach_file"
                                                    id="fileDocument"
                                                    @click:append="open"
                                                >
                                                </v-file-input>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button class="btnCerrar" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                                <v-btn class="btnGuardar" v-if="accionLayout === 'subir'" @click="guardarLayout()" :disabled="invalid" :loading="isSaving">Subir</v-btn>
                                <v-btn class="btnGuardar" v-if="accionLayout === 'descargar'" @click="layout()" :disabled="invalid" :loading="isSaving">Descargar</v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>
            <template>
                <v-dialog v-model="dialogLayout" persistent max-width="500px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" style="display: none;" class="botonModal" id="btnModalLayout"></v-btn>
                    </template>
                    <v-card>
                        <ValidationObserver ref="formArchivo" v-slot="{ invalid }">
                            <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                                <div class="headerModalStart">
                                    <h2 class="titleModal_700_18">{{ tituloLayoutEmpleados }}</h2>
                                </div>
                            </v-card-title>
                            <v-card-text>
                                <div id="padre" v-show="isLoading">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Layout" rules="required">
                                                <v-file-input
                                                    outlined
                                                    label="Seleccionar el documento"
                                                    v-model="archivo.archivo"
                                                    accept=".xlsx"
                                                    required 
                                                    :error-messages="errors"
                                                    prepend-icon=""
                                                    append-icon="attach_file"
                                                    id="fileDocument"
                                                    @click:append="open"
                                                >
                                                </v-file-input>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button class="btnCerrar" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                                <v-btn class="btnGuardar" v-if="accionLayout === 'importar'" @click="guardarLayout()" :disabled="invalid" :loading="isSaving">Subir</v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>


            <v-dialog v-model="dialogErrores" persistent max-width="950px" class="borde-card">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModalErrores"></v-btn>
                </template>
                <v-card class="borde-card">
                    <v-card-title class="padding_izquierdo padding_derecho padding_arriba">
                            <v-btn
                                @click="cerrarModalErrores()"
                                small
                                slot="activator"
                                icon
                                text
                                class="v-btn-cerrar modal-pull-right"
                                absolute
                                top
                                right
                            >
                                <v-icon class="icono-cerrar"></v-icon>
                            </v-btn>
                        <div class="headerModalStart">
                            <h2 class="titleModalErrores">Errores</h2>
                        </div>
                    </v-card-title>

                    <v-card-text class="padding_izquierdo padding_derecho padding_abajo">
                        <div id="padre" v-show="isLoadingError">
                            <div id="loading">
                                <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                            </div>
                        </div>
                        
                        <v-container v-show="!isLoadingError" grid-list-md id="contenedor">
                            <v-row class="pa-0 ma-0">
                                <v-col cols="12" xs="12" sm="12" md="12" class="pa-0 ma-0">
                                    <table class="tbl-errores">
                                        <thead class="tbl-header">
                                            <tr>
                                                <th style="border-top-left-radius: 20px;" class="pl-7 errorth">Empleado</th>
                                                <th style="border-top-right-radius: 20px;" class="errorth">Tipo de error</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="erroresModal.length == 0" >
                                                <td class="emptyTableErrores" colspan="2">
                                                    No hay elementos para mostrar
                                                </td>
                                            </tr>
                                            <template v-else v-for="(error, index) in erroresModal">
                                                <template>
                                                    <tr :key="index">
                                                        <td class="pl-7 errortd pt-4 pb-0">
                                                            <div class="textoTablaError01">{{error.empleado}}</div>
                                                        </td>
                                                        <td class="errortd"> <div class="textoTablaError03">{{error.observaciones}}</div> </td>
                                                    </tr>
                                                    <tr :key="'A'+index" class="trDivider"><div class="my-2"></div></tr>
                                                </template>
                                            </template>
                                        </tbody>
                                    </table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>


        </v-container>
    </div>
</template>

<script>
import Vue from "vue";
//Componentes
import Datatable from '@/components/datatable/Datatable.vue';
import Paquete from '@/components/Paquete';
import ExpandableItem from '@/components/ExpandableItemBeneficiarios.vue';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';

//API's
import apiEmpleados from '@/api/empleados';
import apiClientes from "@/api/clientes";
import apiEmpresas from '@/api/empresas';
import apiRegistroPatronal from "@/api/nominas/registroPatronal";
import apiSucursales from '@/api/sucursales';
import apiTipoNomina from '@/api/nominas/tipoNomina';


//Utilerias
import Notify from '@/plugins/notify';


export default {
    components: {
        'data-table': Datatable,
        Paquete,
        ExpandableItem,
        VuetifyDatePicker
    },
    data() {
        return {
            tabs: 0,
            columns         : [
                {
                    label: 'Clave',
                    name: 'clave',
                    filterable: false
                },
                {
                    label: 'Nombre del empleado',
                    name: 'nombre',
                    filterable: false
                },
                {
                    label: 'Empresa',
                    name: 'empresa',
                    filterable: false
                },
                {
                    label: 'Registro patronal',
                    name: 'registro_patronal',
                    filterable: false
                },
                
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters         : {
                cliente_id  : null,
                empresa_id  : null,
                sucursal_id : null,
                paginate    : true,
                activo      : true,
            },
            url             : 'beneficiarios/find',
            isLoading       : true,
            isLoadingModal  : false,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            beneficiario           : {
                id                      : null,
                empleado_id             : null,
                nombre                  : null,
                apellido_paterno        : null,
                apellido_materno        : null,
                fecha_nacimiento        : null,
                parentesco              : null,
                sexo                    : 'M',
                correo                  : null,
                telefono                : null,
            },
            datosLogin                  : null,
            rol                         : null,
            cliente_value               : null,
            accion                      : null,
            tituloModal                 : null,
            dialog                      : false,
            isSaving                    : false,
            imagen                      : '',

            itemsClientes               : [],
            itemsEmpresas               : [],
            itemsRegistrosPatronales    : [],
            itemsSucursales             : [],
            itemsTiposNominas           : [],

            cliente_value               : null,
            empresa_value               : null,
            registro_patronal_value     : null,
            sucursal_value              : null,
            tipo_nomina_value           : null,
            nombre_completo_value       : null,
            correo_value                : null,

            accionLayout                : '',
            tituloLayoutEmpleados       : '',
            frase                       : '',
            dialogLayoutEmpleados       : false,
            dialogLayout                : false,
            overlayDescarga             : false,
            archivo                     : {
                cliente_id              : null,
                empresa_id              : null,
                sucursal_id             : null,
                archivo                 : null,
            },
            erroresModal                : [],
            dialogErrores               : false,
            isLoadingError              : false,
        }
    },
    watch: {
        cliente_value: function(val) {
            this.getRegistrosPatronales(val);
            this.getEmpresas(val);
        },
        empresa_value: function(val){
            this.getSucursales(val);
            this.getTiposNominas(val);
        }
    },

    methods: {
        open(){
            document.getElementById("fileDocument").click();
        },
        async getClientes() {
            let param = { 
                activo: true, 
                paginate: false 
            };
            this.itemsClientes = [];

            await apiClientes.getClientes(param).then(response => {
                this.itemsClientes = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes");
            });
        },
        async getEmpresas(id) {
            this.itemsEmpresas = [];
            if(id == null){
                return;
            }
            
            let parametros = { 
                activo: true, 
                paginate: false, 
                cliente_id: id 
            };
            await apiEmpresas.getEmpresas(parametros).then((response)=>{
                this.itemsEmpresas = response.data;
            })
            .catch(error => {
                Notify.ErrorToast("Ocurrió un error al cargar las empresas");
            });
        },
        async getSucursales(id) {
            this.itemsSucursales = [];
            if(id == null){
                return;
            }
            
            let parametros = { 
                activo: true, 
                paginate: false, 
                cliente_id: this.cliente_value,
                empresa_id: id
            };
            await apiSucursales.getSucursales(parametros).then((response)=>{
                this.itemsSucursales = response.data;
            })
            .catch(error => {
                Notify.ErrorToast("Ocurrió un error al cargar las sucursales");
            });
        },
        async getRegistrosPatronales(clienteId) {
            this.itemsRegistrosPatronales = null;
            if(clienteId == null){
                return;
            }

            let param = { 
                activo: true, 
                paginate: false, 
                cliente_id: clienteId 
            };
            await apiRegistroPatronal.find(param).then(response => {
                this.itemsRegistrosPatronales = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los registros patronales.");
            });
            
        },
        async getTiposNominas(id) {
            this.itemsSucursales = [];
            if(id == null){
                return;
            }
            
            let parametros = { 
                activo: true, 
                paginate: false, 
                cliente_id: this.cliente_value,
                empresa_id: id
            };
            await apiTipoNomina.find(parametros).then((response)=>{
                this.itemsTiposNominas = response.data;
                this.itemsTiposNominas.map(tipo_nomina => tipo_nomina.claveDescripcion = tipo_nomina.clave + ' - ' + tipo_nomina.descripcion+ " - "+tipo_nomina.nombre_empresa);
            })
            .catch(error => {
                Notify.ErrorToast("Ocurrió un error al cargar las sucursales");
            });
        },
        async abrirModal(data){
            this.accion         = "add";
            this.isLoadingModal = true;

            this.tituloModal    = "Nuevos datos para el beneficiario";
            this.imagen         = "/static/modal/usuarioCreate.svg";
            this.beneficiario.empleado_id = data.id;
                
            this.isLoadingModal  = false;
            this.dialog = true;
        },
        async abrirModalUpdate(data){
            this.accion         = 'update';
            this.isLoadingModal = true;

            this.tituloModal                            = "Modificar datos del beneficiario";
            this.imagen                                 = "/static/modal/usuarioUpdate.svg";
            this.beneficiario.id                        = data.id;
            this.beneficiario.empleado_id               = data.empleado_id
            this.beneficiario.nombre                    = data.nombre
            this.beneficiario.apellido_paterno          = data.apellido_paterno
            this.beneficiario.apellido_materno          = data.apellido_materno
            this.beneficiario.fecha_nacimiento          = data.fecha_nacimiento
            this.beneficiario.parentesco                = data.parentesco
            this.beneficiario.sexo                      = data.sexo
            this.beneficiario.correo                    = data.correo
            this.beneficiario.telefono                  = data.telefono
            
            this.isLoadingModal  = false;
            setTimeout(() => { this.dialog = true }, 100);
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.dialogLayoutEmpleados = false;
            this.dialogLayout = false;
            this.resetValues();
        },
        async resetValues() {
            this.cliente_value = null;
            this.beneficiario           = {
                id                      : null,
                empleado_id             : null,
                nombre                  : null,
                apellido_paterno        : null,
                apellido_materno        : null,
                fecha_nacimiento        : null,
                parentesco              : null,
                sexo                    : 'M',
                correo                  : null,
                telefono                : null,
            };
            this.archivo                = {
                cliente_id              : null,
                empresa_id              : null,
                sucursal_id             : null,
                archivo                 : null,
            };
            this.itemsClientes              = [];
            this.itemsEmpresas              = [];
            this.itemsRegistrosPatronales   = [];
            this.itemsSucursales            = [];
            this.itemsTiposNominas          = [];
            this.cliente_value              = null;
            this.empresa_value              = null;
            this.registro_patronal_value    = null;
            this.sucursal_value             = null;
            this.tipo_nomina_value          = null;
            this.nombre_completo_value      = null;
            this.correo_value               = null;
            this.$nextTick(() => {
                if(this.$refs.form){
                    this.$refs.form.reset();
                }
                if(this.$refs.formArchivo) {
                    this.$refs.formArchivo.reset();
                }
            });
        },
       
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                
                if(this.accion === "add") {
                    apiEmpleados.addBeneficiario(this.beneficiario).then((response) => {
                        Notify.Success("Operación exitosa", "Los datos del beneficiario se guardaron satisfactoriamente.");
                        this.$refs.tabla.getData();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
                else if(this.accion === "update") {
                    apiEmpleados.updateBeneficiario(this.beneficiario).then((response) => {
                        Notify.Success("Operación exitosa", "Los datos del beneficiario se actualizaron satisfactoriamente.");
                        this.$refs.tabla.getData();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            });
        },
        eliminar(data){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar al beneficiario con nombre " + data.nombre + "?",
                "Eliminar",
                () => {
                    apiEmpleados.deleteBeneficiario(data.id).then(response => {
                        Notify.Success("Beneficiario eliminado", "El beneficiario ha sido eliminado satisfactoriamente.");
                        this.$refs.tabla.getData();
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },
        async loadModalData(){
            await this.resetValues();
            if (this.rol == "root") {
                this.getClientes();
            } else if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
            } else if(this.rol == "admin-empresa") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
            else {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.sucursal_value = this.datosLogin.sucursal_id;
            }
        },
        setFilters() {
            let cliente_id              = this.cliente_value;
            let empresa_id              = this.empresa_value;
            let registro_patronal_id    = this.registro_patronal_value;
            let sucursal_id             = this.sucursal_value;
            let tipo_nomina_id          = this.tipo_nomina_value;
            let nombre_completo         = this.nombre_completo_value;
            let correo                  = this.correo_value;
            

            if (this.rol == "root") {

            } else if (this.rol == "admin") {
                cliente_id = this.datosLogin.cliente_id;
            } else if(this.rol == "admin-empresa") {
                cliente_id = this.datosLogin.cliente_id;
                empresa_id = this.datosLogin.empresa_id;
            }
            else {
                cliente_id = this.datosLogin.cliente_id;
                empresa_id = this.datosLogin.empresa_id;
                sucursal_id = this.datosLogin.sucursal_id;
            }

            let filterParams = {
                cliente_id: cliente_id,
                empresa_id: empresa_id,
                tipo_nomina_id: tipo_nomina_id,
                registro_patronal_id: registro_patronal_id,
                sucursal_id: sucursal_id,
                nombre_completo: nombre_completo,
                correo: correo
            };

            let defaultParams = {
                activo    :true,
                paginate  :true,
            };

            if (filterParams != null) Object.assign(defaultParams, filterParams);

            this.filters = defaultParams;

            this.itemsClientes              = [];
            this.itemsEmpresas              = [];
            this.itemsRegistrosPatronales   = [];
            this.itemsSucursales            = [];
            this.itemsTiposNominas          = [];
            this.cliente_value              = null;
            this.empresa_value              = null;
            this.registro_patronal_value    = null;
            this.sucursal_value             = null;
            this.tipo_nomina_value          = null;
            this.nombre_completo_value      = null;
            this.correo_value               = null;
        },


        abrirModalLayoutEmpleados(accion){
            this.accionLayout = accion;
            this.dialogLayoutEmpleados = true;
            this.isLoading = true;
            if(accion === 'descargar' ){
                this.tituloLayoutEmpleados  = 'Descargar layout';
            }
            else if(accion === 'subir'){
                this.tituloLayoutEmpleados  = 'Subir layout';
            }

            if(this.rol == "root"){
                this.getClientes();
            }
            else if (this.rol == "admin") {
                this.archivo.cliente_id = this.datosLogin.cliente_id;
                this.cliente_value = this.datosLogin.cliente_id;
            }
            this.isLoading = false;
        },
        modalLayout(accion) {
            this.accionLayout = accion;
            if(accion === 'importar' ){
                this.tituloLayoutEmpleados  = 'Subir layout';
            }
            this.archivo.cliente_id = this.datosLogin.cliente_id;
            this.archivo.empresa_id = this.datosLogin.empresa_id;
            if (this.rol == "admin-sucursal") {
                this.archivo.sucursal_id = this.datosLogin.sucursal_id;
            }
            this.archivo.rol_name = this.datosLogin.rol.name;

            setTimeout(function() {
                document.getElementById("btnModalLayout").click();
            }, 300);
        },
        async layout() {
            let self = this;
            let url = "beneficiarios/exportar-layout";
            const FileDownload = require("js-file-download");
            let nombre;
            this.frase = "Descargando layout."
            if (this.rol == "admin" || this.rol == "root") {
                this.isSaving = true;
                nombre =
                    "layout_de_importacion" +".xlsx";
                var object1 = {
                    rol_name: "admin-empresa",
                    cliente_id: this.cliente_value,
                    empresa_id: this.empresa_value,
                };
            }
            if (this.rol == "admin-empresa") {
                this.overlayDescarga = true;
                nombre =
                    "layout_de_importacion" +".xlsx";
                var object1 = {
                    rol_name: this.rol,
                    cliente_id: this.datosLogin.cliente_id,
                    empresa_id: this.datosLogin.empresa_id,
                };
            }
            if (this.rol == "admin-sucursal") {
                this.overlayDescarga = true;
                nombre =
                   "layout_de_importacion" +".xlsx";
                var object1 = {
                    rol_name: this.rol,
                    cliente_id: this.datosLogin.cliente_id,
                    empresa_id: this.datosLogin.empresa_id,
                    sucursal_id: this.datosLogin.sucursal_id
                };
            }
            try {
                await Vue.axios({
                    method: "post",
                    responseType: "blob",
                    url: url,
                    params: object1
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    this.cerrarModal();
                    this.overlayDescarga = false;
                })
                .catch(err => {
                    self.isSaving = false;
                    self.overlayDescarga = false;
                    Notify.ErrorToast("Error al descargar archivo.");
                });
            } catch (e) {
                this.overlayDescarga = false;
                this.isSaving = false;
                console.log(e);
            }
        },
        guardarLayout() {
            let self = this;
            this.overlayDescarga = true;
            this.frase = "Guardando datos."
            this.$refs.formArchivo.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                if(this.rol == "root" || this.rol == "admin"){
                    this.archivo.cliente_id = this.cliente_value;
                    this.archivo.empresa_id = this.empresa_value;
                    this.archivo.rol_name   = "admin-empresa";
                }
                let formData = new FormData();
                formData.append("archivo", this.archivo.archivo);
                formData.append("cliente_id", this.archivo.cliente_id);
                formData.append("empresa_id", this.archivo.empresa_id);

                if (this.rol == "admin-sucursal") {
                    formData.append("sucursal_id", this.archivo.sucursal_id);
                }

                formData.append("rol_name", this.archivo.rol_name);
                formData.append("usuario_id", this.datosLogin.id);
                const config = {
                    headers: {
                        responseType: "blob"
                    }
                };
                Vue.axios({
                    method: "post",
                    url: "beneficiarios/importar-layout",
                    data: formData,
                    config: { headers: { "Content-Type": "multipart/form-data" } }
                })
                .then(function(response) {
                    if (response.status == 200) {
                        Notify.Success("Operación exitosa");
                        self.cerrarModal();
                        self.$refs.tabla.getData();
                        
                    } else if (response.status == 202) {
                        Notify.ErrorToast("Se encontraron errores en los datos");
                        self.erroresModal = response.data;
                        self.dialogErrores = true;
                        self.cerrarModal();
                        self.$refs.tabla.getData();
                        
                    } else {
                        self.cerrarModal();
                        self.$refs.tabla.getData();
                    }
                    self.overlayDescarga = false;
                })
                .catch(err => {
                    self.isSaving = false;
                    self.overlayDescarga = false;
                    Notify.ErrorToast("Error al subir archivo.");
                });
            });
        },
        cerrarModalErrores(){
            this.dialogErrores = false;
            this.erroresModal = [];
        },



        /**
         * @method redireccionar Método que redirecciona a una vista
         * @description Método que redirecciona a la vista dashboard si el no es root, admin, admin-empresa
         */
        redireccionar(){
            this.$router.push('/dashboard');
        },
    },
    created(){
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;

        if(this.rol == 'root'){

        }
        else if(this.rol == 'admin'){
            this.filters.cliente_id = this.datosLogin.cliente_id;
        }
        else if(this.rol == 'admin-empresa'){
            this.filters.cliente_id = this.datosLogin.cliente_id;
            this.filters.empresa_id = this.datosLogin.empresa_id;
        }
        else{
            this.filters.cliente_id = this.datosLogin.cliente_id;
            this.filters.empresa_id = this.datosLogin.empresa_id;
            this.filters.sucursal_id = this.datosLogin.sucursal_id;
        }
    },
    mounted(){
        this.isLoading = false;
    },
}
</script>

<style scoped>
    .modal-subtitle {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #1E2144;
    }

    .tabText{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 17px;
        color: #1E2245;
        text-transform: none;
        background-color: #2DB9D1;
        height: 6px;
        width: 100%;
        margin: 0;
        border-radius:6px

    }
    .tabTextDisable{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 17px;
        color: #BDBDBD;
        text-transform: none;
        background-color: #828282;
        height: 1px;
        width: 100%;
        margin: 0;

    }

    .titleWizard {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #1E2245;
    }

    .v-btn-cerrar:hover:before {
      background-color: transparent;
      
    }
    .v-btn-cerrar:before {
        display: none;
    }
    .tituloRadio{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #828282;
        padding-bottom: 10px;
    }
    .menuClassAcciones{
        /* box-shadow: none; */
        border-radius: 10px!important;
        margin-top:15px;
    }
    .btnAcciones {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: capitalize;
        background:#58C5D8 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
    }
    .btnAcciones:hover {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: capitalize;
        background:#58C5D8 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
        box-shadow: 0px 5px 8px rgba(26, 30, 66, 0.2);
        -webkit-transform: translatey(-1px);
        transform: translatey(-1px);
    }
    .btnAccionesContenedor {
        width: 300px;
        height: auto;
        padding-top: 30px;
        padding-bottom: 30px;
        background-color: #FFF;
    }
    div.btnAccionesTitulo {
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesTitulo span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: left;
        color: #1E2245;
    }
    div.btnAccionesItem {
        width: 100%;
        cursor: pointer;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesItem span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: #828282;
    }
    div.btnAccionesItem:hover {
        background: #F6F6F8;
    }
    #btnModalErrores{
        display: none;
    }

    .titleModalErrores{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #444975;
    }
    .emptyTableErrores{
        padding-top: 50px !important;
        padding-bottom: 50px !important;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center !important;
        color: #c5c5c5;
    }
    .tbl-errores {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        /* white-space: nowrap; */
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .tbl-errores .tbl-header {
        background: #F6F6F6;
        border-radius: 10px;
        height: 58px;
    }
    .errorth {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #828282;
        line-height: 20px;
        padding: 8px;
        text-align: left !important;
    }
    .errortd {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #96999A;
        line-height: 15px;
        padding: 8px;
        text-align: left !important;
    }
    .textoTablaError01{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1E2245;
        text-align: left !important;
    }
    .textoTablaError02{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #828282;
        text-align: left !important;
    }
    .textoTablaError03{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #EB5757;
        line-height: 17px;
        text-align: left !important;
    }

</style>
