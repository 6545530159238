<template>
    <div class="pa-0 ma-0">
        <!--Parte visible del componente-->
        <tr>
            <td style=""><div>{{item.clave}}</div></td>   
            <td style=""><div>{{item.nombre}}</div></td>   
            <td style=""><div>{{item.empresa}}</div></td>     
            <td style=""><div>{{item.registro_patronal}}</div></td>        

            <td >
                <div class="tblOpciones">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="agregar()">
                                <v-icon class="iconoDelgadoBoton">add</v-icon>
                            </v-btn>
                        </template>
                        <span>Agregar beneficiario</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="beneficiarios.length > 0">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" icon fab dark small @click="isOpen ? isOpen = false:isOpen = true">
                                <v-icon class="tamIconoBoton iconoDelgadoBoton">{{isOpen ? 'keyboard_arrow_up':'keyboard_arrow_down'}}</v-icon>
                            </v-btn>
                        </template>
                        <span>Desplegar</span>
                    </v-tooltip>
                </div>
            </td>
        </tr>


        <!--Parte desplegable del componente-->
        <template v-if="isOpen">
            <tr class="td-titles">
                <td>
                    <div>
                        Nombre del beneficiario
                    </div>
                </td>
                <td>
                    <div>
                        Teléfono
                    </div>
                </td>
                <td>
                    <div>
                        Correo
                    </div>
                </td>
                
                <td >
                    <div class="tblOpciones">
                        Opciones
                    </div>
                </td>
            </tr>
            <tr :key="datos.id" v-for="datos in beneficiarios"  class="td-short">
                               
                <td>
                    <div>
                        {{datos.nombre}}
                    </div>
                </td>
                <td>
                    <div>
                        {{datos.telefono}}
                    </div>
                </td>
                <td>
                    <div>
                        {{datos.correo}}
                    </div>
                </td>
                
                <td style="padding-left:8px; padding-right:8px;">
                    <div class="tblOpciones">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal(datos)">
                                    <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(datos)">
                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Eliminar</span>
                        </v-tooltip>
                    </div>
                </td>
            </tr>
        </template>
    </div>
</template>


<script>
import GetUrlImg from "@/plugins/Urlimagen";
import moment from 'moment';
moment.locale('es');

export default {
    name: 'expandable-item',
    props: {
        item: {
            type: Object,
            required: true,
            default: ''
        },
        full: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            isOpen: false,
            urlImg : '',
        }
    },
    computed: {
        beneficiarios(){
            return this.item.beneficiarios;
        }
    },
    methods: {
        agregar(){
            this.$emit("abrirModal", this.item);
        },
        abrirModal(data){
            this.$emit("abrirModalUpdate", data);
        },
        eliminar(data){
            this.$emit("eliminar", data);
        }
        
        
    },
    //MOUNTED se ejecutan instrucciones una vez se crea el componente;
    mounted () {
        this.urlImg = GetUrlImg.getURL();
    },
}
</script>

<style scoped>
    table {
        border-collapse: collapse;
        width: 100%;
        overflow-x: scroll;
    }
    th{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: left;
        color: #828282;
        background-color: #f6f5f6;
        padding: 13px 45px 13px 45px;
    }
    th > div{
        max-width: 15rem;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
    }
    td{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: left;
        color: #1E2245;
        background-color: #fdfcfc;
        padding: 13px 45px 13px 45px;
        letter-spacing: -0.34px;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        max-width: 15rem;
    }

    td > div{
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        max-width: 15rem;
        margin-right: auto;
        margin-left: auto;
    }
    td > .loading{
        max-width: 100% !important;
    }
    
    tbody {
      display:block;
      max-height:60vh;
      min-width: 1000px;
    }
    tr {
      display:table;
      table-layout:fixed;
      width: 100%;
    }
    table.ck-table.modal  tbody {
      display:block;
      max-height:60vh;
      min-width: 700px;
    }

    .td-titles td{
        padding-top:1px !important;
        padding-bottom:1px !important;
        background: #f6f6f6;
    }

    .td-short td{
        padding-top:1px !important;
        padding-bottom:1px !important;
        background: #fdfcfc;
    }

    tbody > div {
        vertical-align: middle !important;
    }
    
</style>